<script setup>

import {watch, reactive, computed, ref} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {i18n, AutoSaveStack, PopUpManager as PopUp, STATE} from '@/Ship';
import { useCampaignActions } from '@campaign';
import { useBuyerActions } from '@buyer';
import { useProfileActions } from '@profile';
import { useOfferActions } from "@offer";
import { useBrandActions } from '@brand';
import {useCountryActions} from "@country";
import {useUserActions} from "@user";

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaign = getCampaignResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getBrandResource = useBrandActions('getBrandResource');
const brands = getBrandResource();

const getOfferResource = useOfferActions('getOfferResource');
const offers = getOfferResource();

const getCountryResource = useCountryActions('getCountryResource');
const countryResource = getCountryResource({ is_disabled: [0] });

const getBuyerResource = useBuyerActions('getBuyerResource');
const buyerResource = getBuyerResource();
const buyers = computed(() => {
    return buyerResource.state.filter(buyer => buyer.user_id === (payload.affiliate_id || campaign.model.affiliate_id));
});

const affiliates = ref([]);
const getAffiliates = useUserActions('getAffiliates');
getAffiliates().then(response => {
    affiliates.value = response.data;
});

const router = useRouter();
const route = useRoute();

watch(campaign.entity, () => {
    Object.assign(campaign.state[campaign.index], campaign.entity);
});

function removeCampaign() {
    PopUp
        .setCaption(i18n.global.t('campaign.remove_campaign', 'Remove Campaign'))
        .setMessage(`<p class="message"><b>${i18n.global.t('campaign.remove_confirm', { id: campaign.model.id }, 'The Campaign with ID:{id} will be permanently deleted')}!</b></p></p>`)
        .setAction(() => {
            campaign.delete(campaign.model.id).then(()=> {
                campaign.state.splice(campaign.index, 1);
                campaign.index = null;
                router.push({ name: 'Campaigns', params: route.params })
            })

            PopUp.close();
        })
        .open(require('@/Ship/components/PopUps/Confirm'));
}

const showAllCountries = ref(false);
const languages = ref(['EN', 'RU', 'PL']);

const countryFind = ref();
const countries = computed(() => {
    return showAllCountries.value
        ? (state => {
            return countryFind.value
                ? state.filter(country => country.name.toLowerCase().includes(countryFind.value.toLowerCase()))
                : state
        })(countryResource.state)
        : countryResource.state.filter(country => country.is_favorite || campaign.model.geo.includes(country.code));
});

const payload = reactive({});
const errors = reactive({});

function addFieldToChangeList(field) {
    if (!STATE.UNSAVED_DATA.campaign) {
        STATE.UNSAVED_DATA.campaign = [];
    }
    if (!STATE.UNSAVED_DATA.campaign.includes(field)) {
        STATE.UNSAVED_DATA.campaign.push(field);
    }
}

function setValue(key, value) {
    if (campaign.model[key] === value) {
        delete payload[key];
        STATE.UNSAVED_DATA.campaign.splice(STATE.UNSAVED_DATA.campaign.indexOf(i18n.global.t(`campaign.${key}`, key)), 1);
    } else {
        payload[key] = value;
        addFieldToChangeList(i18n.global.t(`campaign.${key}`, key));
    }
}

function setBrandCheckValue(mode) {
    if (mode === 'setTrue') {
        payload.is_brand_check = 'Yes';
        addFieldToChangeList(i18n.global.t('campaign.lead_uniqueness'));
    } else if (mode === 'setFalse') {
        payload.is_brand_check = 'No';
        addFieldToChangeList(i18n.global.t('campaign.lead_uniqueness'));
    } else if (mode === 'delete') {
        delete payload.is_brand_check;
        STATE.UNSAVED_DATA.campaign.splice(STATE.UNSAVED_DATA.campaign.indexOf(i18n.global.t('campaign.lead_uniqueness')), 1);
    }
}

const name = computed({
    get: () => payload.hasOwnProperty("name") ? payload.name : campaign.model.name,
    set: (value) => {
        setValue('name', value);
        return true;
    }
});

const source = computed({
    get: () => payload.hasOwnProperty("source") ? payload.source : campaign.model.source,
    set: (value) => {
        setValue('source', value);
        return true;
    }
});

const offer = computed({
    get: () => payload.hasOwnProperty("offer_id") ? payload.offer_id : campaign.model.offer_id,
    set: (value) => {
        setValue('offer_id', value);
        return true;
    }
});

const language = computed({
    get: () => payload.hasOwnProperty("language") ? payload.language : campaign.model.language,
    set: (value) => {
        setValue('language', value);
        return true;
    }
});

const alias = computed({
    get: () => payload.hasOwnProperty("alias") ? payload.alias : campaign.model.alias,
    set: (value) => {
        setValue('alias', value);
        return true;
    }
});

const brand = computed({
    get: () => payload.brand_id || campaign.model.brand_id,
    set: (value) => {
        setValue('brand_id', value);
        return true;
    }
});

const geo = computed({
    get: () => payload.geo || campaign.model.geo,
    set: (value) => {

        const countries = campaign.model.geo;

        if (value.some(item => !countries.includes(item)) || countries.some(item => !value.includes(item))) {
            payload.geo = value;
            addFieldToChangeList(i18n.global.t('campaign.countries', 'Countries'));
        } else {
            STATE.UNSAVED_DATA.campaign.splice(STATE.UNSAVED_DATA.campaign.indexOf(i18n.global.t('campaign.countries', 'Countries')), 1);
            delete payload.geo;
        }

        return true;
    }
});

const affiliate = computed({
    get: () => payload.affiliate_id || campaign.model.affiliate_id,
    set: (value) => {
        setValue('affiliate_id', value);
        return true;
    }
});

const buyer = computed({
    get: () => payload.buyer_id || campaign.model.buyer_id,
    set: (value) => {
        setValue('buyer_id', value);
        return true;
    }
});

const type = computed({
    get: () => payload.type || campaign.model.type,
    set: (value) => {
        setValue('type', value);
        return true;
    }
});

const cost = computed({
    get: () => payload.hasOwnProperty("cost") ? payload.cost : campaign.model.cost,
    set: (value) => {
        setValue('cost', value);
        return true;
    }
});

const leadUniq = computed({
    get: () => {
        const value = payload.is_brand_check || campaign.model.is_brand_check;        
        return value === 'Yes';
    },
    set: (value) => {
        if (value && campaign.model.is_brand_check === 'Yes') {
            setBrandCheckValue('delete');
        } else if (value && campaign.model.is_brand_check === 'No') {
            setBrandCheckValue('setTrue');
        } else if (!value && campaign.model.is_brand_check === 'No') {
            setBrandCheckValue('delete');
        } else if (!value && campaign.model.is_brand_check === 'Yes') {
            setBrandCheckValue('setFalse');
        }
        return true;
    }
});

function affiliateChoice(id) {
    if (buyers.value.length === 1) {
        buyerChoice(buyers.value[0].id);
    } else {
        buyers.index = null;
        delete campaign.entity.buyer_id;
    }
}

function buyerChoice(id) {
    const buyer = buyerResource.state.find(buyer => buyer.id === id);
    //buyerResource.index = buyerResource.state.findIndex(buyer => buyer.id === id);
    //campaign.model.buyer_id = buyerResource.model.id;
    payload.buyer_id = buyer.id;
    payload.type = buyer.type;
    payload.cost = buyer.cost;
}

function closeCampaign() {
    let isLock = false;
    Object.keys(STATE.UNSAVED_DATA).forEach(key => {
        if (STATE.UNSAVED_DATA[key].length > 0) {
            isLock = true;
        }
    });
    isLock
        ? PopUp.open(require('@/Ship/components/PopUps/UnSavedAlert')).setCaption(i18n.global.t('message.saving_data', 'Saving data'))
        : router.push({ name: 'Campaigns', params: route.params });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
    STATE.UNSAVED_DATA.campaign = [];
}

function save() {
    campaign.save(campaign.model.id, payload).then(() => {

        Object.keys(payload).forEach(key => {
            campaign.state[campaign.index][key] = payload[key];
        });

        reset();
    });
}
</script>

<template>
    <div>
        <div class="viewbar">
            <span class="close-btn" v-on:click="closeCampaign">
                <g-symbol name="close" width="24" height="24"/>
            </span>
        </div>
        <div class="card">
            <g-preloader-content :is-loading="campaign.isLoading"/>
            <g-flex align="center" justify="between" class="lead-bar">
                <g-caption size="3">ID: {{ campaign.model.id }}</g-caption>
                <div>
                    <g-button v-if="profile.can('Campaign Remove')"
                              class="remove-btn with-tooltip"
                              type="button"
                              v-on:click="removeCampaign"
                              v-bind:data-tooltip="$t('base.remove_campaign', 'Remove')">
                        <g-symbol name="delete" width="18" height="18"/>
                    </g-button>
                </div>
            </g-flex>

            <!--<campaign-form v-if="campaign.model.id" v-model="campaign.model"/>-->

            <div class="campaign">
                <div class="container">
                    <g-field v-model="name"
                             v-bind:label="$t('campaign.campaign_name', 'Campaign Name')"
                             v-bind:readonly="profile.cant('Campaign Edit')"
                             v-bind:error="errors.name">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-field>
                    <g-field v-model="source"
                             v-bind:label="$t('campaign.source', 'Source')"
                             v-bind:readonly="profile.cant('Campaign Edit')">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-field>

                    <g-field v-model="alias"
                             v-bind:label="$t('campaign.alias', 'Alias')"
                             v-bind:readonly="profile.cant('Campaign Edit')">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-field>

                    <!--
                    <g-fade-container class="offer-select" icon="offers" v-bind:label="offer">
                        <div class="offer-menu">
                            <label v-for="item in offers.state"
                                   class="menu-item"
                                   v-bind:key="`offer-${item.id}`"
                                   v-on:click="setValue('offer_id', item.id)">
                                {{ item.name }}
                                <span>[{{ item.language }}]</span>
                            </label>
                        </div>
                    </g-fade-container>
                    -->

                    <g-select v-model="offer"
                              v-bind:label="$t('campaign.offer', 'Offer')"
                              v-bind:options="offers.state.map(item => { return { id: item.id, name: `${item.language} &nbsp; ${item.name}` } })"
                              option-text="name"
                              option-value="id"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>

                    <!--
                    <g-combobox v-model="language"
                                v-bind:label="$t('base.language', 'Language')"
                                v-bind:options="languages"
                                v-bind:disabled="profile.cant('Campaign Edit')"/>
                    -->

                    <!--
                    <g-select v-model="brand"
                              v-bind:label="$t('campaign.brands', 'Brand')"
                              v-bind:options="brands.state"
                              option-text="name"
                              option-value="id"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                    -->
                </div>

                <fieldset class="geo fieldset">
                    <legend>
                        <g-input v-if="showAllCountries" v-model="countryFind">
                            <g-symbol name="search" width="20" height="20"/>
                        </g-input>
                        <template v-else>{{ $t('campaign.geo') }}</template>
                    </legend>
                    <g-flex direction="column" align="center">
                        <div class="countries checkboxes">
                            <g-checkbox v-model="geo"
                                        v-for="country in countries"
                                        v-bind:key="`country-${country.code}`"
                                        v-bind:value="country.code"
                                        v-bind:disabled="profile.cant('Campaign Edit')">
                                {{ $t(`countries.${country.code}`) }}
                            </g-checkbox>
                        </div>


                        <button type="button"
                                class="show-countries"
                                v-bind:class="{ hidden: showAllCountries }"
                                v-on:click="showAllCountries = !showAllCountries">
                            {{ showAllCountries ? $t('countries.hide_fav', 'Hide not favorite Countries') : $t('countries.show_all', 'Show all Countries') }}
                        </button>
                    </g-flex>
                </fieldset>

                <fieldset class="lead-uniq fieldset">
                    <legend>
                        {{ $t('campaign.lead_uniqueness') }}
                    </legend>
                    <g-flex direction="column" align="center">
                        <div class="checkboxes">
                            <g-checkbox v-model:checked="leadUniq">
                                {{ $t('campaign.brand') }}
                            </g-checkbox>
                        </div>
                    </g-flex>
                </fieldset>

                <div class="container">
                    <g-select v-model="affiliate"
                              v-bind:label="$t('campaign.affiliate', 'Affiliate')"
                              v-bind:options="affiliates"
                              option-text="name"
                              option-value="id"
                              v-on:select="affiliateChoice"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>

                    <g-select v-model="buyer"
                              v-bind:label="$t('campaign.buyer', 'Media Buyer')"
                              v-bind:options="buyers"
                              option-text="name"
                              option-value="id"
                              v-on:select="buyerChoice"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                    <!--v-bind:disabled="!buyers.state.length && !modelValue.buyer_id"-->

                    <g-select v-model="type"
                              v-bind:label="$t('campaign.type', 'Type')"
                              v-bind:options="[{value: 'CPA'}, {value: 'CPL'}]"
                              option-text="value"
                              option-value="value"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                    <g-field v-model="cost"
                             v-bind:label="$t('campaign.cost', 'Cost')"
                             v-bind:readonly="profile.cant('Campaign Edit')">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-field>
                </div>

                <g-flex class="btn-bar" justify="end" gap="4">
                    <g-button class="cancel-btn" type="button" v-on:click="reset" v-bind:disabled="!Object.keys(payload).length">
                        <g-symbol name="refresh" width="18" height="18"/>
                        {{ $t('base.reset', 'Reset') }}
                    </g-button>
                    <g-button class="save-btn" type="button" v-on:click="save" v-bind:disabled="!Object.keys(payload).length">
                        <g-symbol name="save" width="18" height="18"/>
                        {{ $t('base.save', 'Save') }}
                    </g-button>
                </g-flex>

            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.card {
    padding: 20px;
}

.container {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    padding: 15px 0;

    & > .g-field,
    & > .g-select,
    & > .g-combobox,
    & > .g-fade-container {
        width: calc(50% - 5px);
    }
}

.save-btn {
    min-width: 100px;
    padding: 0 20px;
    color: white;
    background-color: var(--save-btn-bg, $save-btn-bg);
}

.remove-btn {
    width: 35px;
    height: 35px;
    color: white;
    margin: 0 2px;
    font-size: 0;
    background-color: transparent;
    fill: var(--main-text-color, $main-text-color);
    border: 1px solid var(--main-text-color, $main-text-color);
    position: relative;

    &::after {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover {
        fill: var(--btn-primary-color, $btn-primary-color);
        border-color: var(--danger, $danger);
        background-color: var(--danger, $danger);

        &::after {
            background-color: var(--danger, $danger);
        }

        &::before {
            border-bottom-color: var(--danger, $danger);
        }
    }
}

.offer-select {
    height: var(--input-height, $input-height) !important;
    border: 1px solid var(--input-border, $input-border);
    //background-color: #1e9d97;

    &:deep(label) {
        justify-content: start !important;
    }
}

.offer-menu {
    padding: 10px;
    background-color: var(--bar-bg, $env-bg);

    & > .menu-item {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        margin: 5px 0;
        cursor: pointer;
        color: var(--main-text-color, $main-text-color);
        //fill: var(--main-text-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
            //fill: var(--title-text-color, $title-text-color);
            //background-color: var(--primary, $primary);
        }

        &.disabled {
            pointer-events: none;
            color: var(--disabled-color, $disabled-color);
            fill: var(--disabled-color, $disabled-color);
        }
    }
}

.fieldset {
    margin-top: 10px;
    padding: 10px 10px 0;
    border-right: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    .checkboxes {
        flex-grow: 1;
        width: 100%;
        padding-bottom: 5px;

        & > .g-checkbox {
            display: inline-block;
            overflow: hidden;
            width: 100%;
            max-width: 120px;
            white-space: nowrap;
        }
    }
}

.geo {
    .countries {
        column-count: 4;
        height: 180px;
        overflow-y: auto;
    }
}

.show-countries {
    font-size: 0.9rem;
    background-color: transparent;

    &:not(.hidden) {
        color: var(--success-hover, $success-hover);

        &::after {
            content: ' \2193';
        }
    }

    &.hidden {
        color: var(--danger-hover, $danger-hover);

        &::after {
            content: ' \2A2F';
        }
    }
}

.btn-bar {
    padding: 10px 0;

    & > .g-button {
        padding: 0 20px;
        min-width: 120px;
    }
}
</style>