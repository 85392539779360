<script setup>

import { defineEmits, onMounted, reactive } from 'vue';
import { useProfileActions } from '@profile';
import { useLeadActions, useLeadResources } from '@lead';

const emit = defineEmits(['close']);

const filters = useLeadResources('FilterState');

const {
    getFilters,
    getLeadResource
} = useLeadActions();
const leads = getLeadResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const filterset = reactive({});

onMounted(() => {
    getFilters().then(response => {
        Object.assign(filterset, response.data);
        Object.assign(leads.filterset, response.data);
    })
})

function filtrate() {

    Object.keys(filters.state).forEach(section => {
        if (!Object.keys(filters.state[section]).length) {
            delete filters.state[section];
        }
    });

    leads.setFilters(filters.state);
    leads.setPage(1);
    leads.load();

    const brandId = profile.state.env.brand || 0;
    profile.state.lead.filters[brandId] = filters.state;
    profile.saveState();
    emit('close');
}

function close() {
    Object.keys(filters.state).forEach(filter => {
        delete filters.state[filter];
    });
    Object.assign(filters.state, leads.filters);

    emit('close');
}

</script>

<template>
    <form class="filters" v-on:submit.prevent="filtrate">
        <div class="container custom-scrollbar">

            <!-- CONVERSION STATUS -->
            <g-filterset v-model="filters.state.conversion_status"
                        v-bind:label="$t('filters.conversion_status', 'Conversion status')"
                        v-bind:filterset="filterset.conversion_status"
                        is-select-all
                        is-search
                        expanded/>

            <!-- RETENTION STATUS -->
            <g-filterset v-model="filters.state.retention_status"
                        v-bind:label="$t('filters.retention_status', 'Retention status')"
                        v-bind:filterset="filterset.retention_status"
                        is-select-all
                        is-search
                        expanded/>


            <!-- CAMPAIGN -->
            <g-filterset v-model="filters.state.campaigns"
                        v-bind:label="$t('filters.campaigns', 'Campaigns')"
                        value-field="id"
                        v-bind:key-field="profile.can('Lead RealCampaignView') ? 'key' : 'alias'"
                        v-bind:filterset="filterset.campaigns"
                        is-select-all
                        is-search
                        expanded/>

            <div>
                <!-- FTD -->
                <g-filterset v-model="filters.state.is_ftd"
                            v-bind:label="$t('filters.is_ftd', 'Is FTD')"
                            value-field="value"
                            v-bind:filterset="filterset.is_ftd"
                            is-select-all
                            expanded/>

                <!-- PHONE CONFIRMED -->
                <g-filterset v-model="filters.state.phone_confirmed"
                            v-bind:label="$t('filters.phone_confirmed', 'Phone confirmed')"
                            value-field="value"
                            v-bind:filterset="filterset.phone_confirmed"
                            is-select-all
                            expanded/>

                <!-- EMAIL CONFIRMED -->
                <g-filterset v-model="filters.state.email_confirmed"
                            v-bind:label="$t('filters.email_confirmed', 'Email confirmed')"
                            value-field="value"
                            v-bind:filterset="filterset.email_confirmed"
                            is-select-all
                            expanded/>

                <!-- Is TEST -->
                <g-filterset v-model="filters.state.is_test"
                            v-bind:label="$t('filters.is_test', 'Is Test')"
                            value-field="value"
                            v-bind:filterset="filterset.is_test"
                            is-select-all
                            expanded/>
            </div>

            <!-- TEAM -->
            <g-filterset v-model="filters.state.teams"
                        v-bind:label="$t('filters.teams', 'Teams')"
                        v-bind:filterset="filterset.teams"
                        value-field="id"
                        is-select-all
                        is-search
                        expanded/>

            <!-- SALE -->
            <g-filterset v-model="filters.state.sales"
                        v-bind:label="$t('filters.sales', 'Sale agents')"
                        value-field="id"
                        v-bind:filterset="filterset.sales"
                        is-select-all
                        is-search
                        expanded/>

            <!-- RETENTION -->
            <g-filterset v-model="filters.state.retentions"
                        v-bind:label="$t('filters.retentions', 'Retention agents')"
                        value-field="id"
                        v-bind:filterset="filterset.retentions"
                        is-select-all
                        is-search
                        expanded/>

            <!-- AFFILIATE -->
            <g-filterset v-model="filters.state.affiliates"
                        v-bind:label="$t('filters.affiliates', 'Affiliates')"
                        v-bind:filterset="filterset.affiliates"
                        value-field="id"
                        is-select-all
                        is-search
                        expanded/>

            <!-- COUNTRY -->
            <g-filterset v-model="filters.state.countries"
                        v-bind:label="$t('filters.countries', 'Countries')"
                        value-field="code"
                        v-bind:filterset="filterset.countries"
                        is-select-all
                        is-search
                        expanded/>

            <!-- TIMEZONE -->
            <g-filterset v-model="filters.state.timezone"
                        v-bind:label="$t('filters.timezone', 'Timezone')"
                        v-bind:filterset="filterset.timezone"
                        is-select-all
                        is-search
                        expanded/>

            <!-- LANGUAGE -->
            <g-filterset v-model="filters.state.language"
                        v-bind:label="$t('filters.languages', 'Languages')"
                        v-bind:filterset="filterset.language"
                        is-select-all
                        expanded/>

            <!-- ACCOUNT LEVEL -->
            <g-filterset v-model="filters.state.account_level"
                        v-bind:label="$t('filters.level', 'Account level')"
                        v-bind:filterset="filterset.account_level"
                        is-select-all
                        expanded/>

            <!-- KYC STATUS -->
            <g-filterset v-model="filters.state.kyc_status"
                        v-bind:label="$t('filters.kyc_status', 'KYC status')"
                        v-bind:filterset="filterset.kyc_status"
                        is-select-all
                        expanded/>

            <!-- GENDER -->
            <g-filterset v-model="filters.state.gender"
                        v-bind:label="$t('filters.gender', 'Gender')"
                        v-bind:filterset="filterset.gender"
                        is-select-all
                        expanded/>

            <!-- MARITAL STATUS -->
            <g-filterset v-model="filters.state.marital_status"
                        v-bind:label="$t('filters.marital_status', 'Marital Status')"
                        v-bind:filterset="filterset.marital_status"
                        is-select-all
                        expanded/>

        </div>

        <g-flex class="btn-bar" justify="end" gap="5">
            <g-button class="cancel-btn" type="button" v-on:click="close">{{ $t('base.cancel') }}</g-button>
            <g-button class="save-btn">{{ $t('base.apply') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.filters {
    width: 1124px;
}

.container {
    overflow: auto;
    padding: 0 10px;
    max-height: calc(100vh - 220px);
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
}

.btn-bar {
    padding-top: 20px;

    & > .g-button {
        padding: 0 20px;
        min-width: 100px;
    }
}

</style>