<script setup>

import { useFinanceResources } from '@finance';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

const profile = getProfileResource();
if (!profile.state.finances) {
    profile.state.finances = { filters: {}};
}

const deposits = useFinanceResources('Deposit');
const filters = useFinanceResources('FilterState');

Object.assign(filters, profile.state.finances.filters);

deposits.setFilters(filters);

function filtrate() {
    Object.keys(filters).forEach(section => {
        if (!Object.keys(filters[section]).length) {
            delete filters[section];
        }
    });

    deposits.setFilters(filters);
    deposits.load();

    Object.assign(profile.state.finances.filters, deposits.filters);
    profile.state.finances.filters = filters;
    profile.saveState();
}
</script>

<template>
    <!-- Is TEST -->
    <g-filterset v-model="filters.is_test"
                v-bind:label="$t('filters.is_test', 'Is Test')"
                value-field="value"
                v-bind:filterset="deposits.filterset.is_test"
                v-on:change="filtrate"
                expanded/>

    <!-- FTD -->
    <g-filterset v-model="filters.is_ftd"
                v-bind:label="$t('filters.is_ftd', 'Is FTD')"
                value-field="value"
                v-bind:filterset="deposits.filterset.is_ftd"
                v-on:change="filtrate"
                expanded/>

  <!-- Is FORWARDED -->
  <g-filterset v-model="filters.is_forwarded"
              v-bind:label="$t('filters.is_forwarded', 'Is Forwarded')"
              value-field="value"
              v-bind:filterset="deposits.filterset.is_forwarded"
              v-on:change="filtrate"
              expanded/>

    <!-- TYPE -->
    <g-filterset v-model="filters.type"
                v-bind:label="$t('filters.type', 'Type')"
                value-field="key"
                v-bind:filterset="deposits.filterset.type"
                v-on:change="filtrate"
                expanded/>

    <!-- STATUS -->
    <g-filterset v-model="filters.status"
                v-bind:label="$t('filters.status', 'Status')"
                value-field="key"
                v-bind:filterset="deposits.filterset.status"
                v-on:change="filtrate"
                expanded/>

    <!-- METHOD -->
    <g-filterset v-model="filters.method"
                v-bind:label="$t('filters.method', 'Method')"
                value-field="key"
                v-bind:filterset="deposits.filterset.method"
                v-on:change="filtrate"
                expanded/>

    <!-- AFFILIATE -->
    <g-filterset v-model="filters.agents"
                v-bind:label="$t('filters.agents', 'Agents')"
                value-field="id"
                v-bind:filterset="deposits.filterset.agents"
                v-on:change="filtrate"
                expanded/>

    <!-- TEAMS -->
    <g-filterset v-model="filters.teams"
                v-bind:label="$t('filters.teams', 'Teams')"
                v-bind:filterset="deposits.filterset.teams"
                value-field="id"
                v-on:change="filtrate"
                expanded/>
</template>

<style lang="scss" scoped>
.g-filterset {
    border: none !important;
    padding: 10px 16px 20px 10px;

    &:deep(.value) {
        max-width: 120px !important;
    }
}
</style>