import { ref, reactive, computed } from 'vue';
import {
    STACK,
    i18n,
    ApiClient,
    ValidRules,
    //Validator,
    ResourcesManager,
} from '@/Ship';

let errorList = [];

export default class BaseResource {

    referer = 'Resource';

    loaded = ref(false);
    loading = ref(false);

    idx = ref(null);
    entity = reactive({});
    collection = ref([]);
    meta = reactive({});
    filterset = reactive({});
    validate = reactive({});

    filters = reactive({});
    includes = null;
    sortBy = null;
    sortDir = 'asc';
    perPage = null;
    page = 1;
    onload = [];

    constructor() {
        this.model = new Proxy(this.entity, {
            get: (target, key) => {
                // return key in this.entity && !Array.isArray(this.entity[key])
                return key in this.entity
                    ? this.entity[key]
                    : this.collection.value[this.idx.value]?.[key] || '';
            },
            set: (target, key, value) => {
                this.entity[key] = value;
                //this.initValidation(key, value);
                return true;
            }
        });
        // Object.assign(this.validate, this.rules);
        // for (let key in this.validate) {
        //     this.validate[key] = true;
        // }
    }

    initValidation(key, value) {
        if (this.rules[key]) {
            this.validate[key] = this.errors(this.rules[key], value);
        }
    }

    errors(rules, value) {
        let processedRules = typeof rules === 'object' ? rules : [];

        if (typeof rules === 'string') {
            const separatedRules = rules.split('|');

            separatedRules.forEach(rule => {
                rule = rule.replace(/\s/g, '');
                let isRuleHasParam = rule.indexOf(':') > -1 ? rule.split(':') : rule;

                processedRules.push(isRuleHasParam);
            });
        }

        const validRules = new ValidRules();

        processedRules.forEach((rule, index) => {
            if (typeof rule === 'string') {
                validRules[rule](value)
                    ? errorList.splice(index, 1)
                    : errorList = [i18n.global.t(`validator.${rule}`)];
            } else {
                let param = rule[1];

                validRules[rule[0]](value, param)
                    ? errorList.splice(index, 1)
                    : errorList = [i18n.global.t(`validator.${rule[0]}`)];
            }
        });

        return errorList;
    }

    isValidationPassed = computed(() => {
        let isValid = [];
        for (let key in this.validate) {
            isValid = (isValid && !this.validate[key].length);
        }

        return isValid;
    });

    set index(value) {
        this.idx.value = Number.isInteger(value) ? value : null;
        this.clearEntity();
    }

    get index() {
        return this.idx.value;
    }

    get state() {
        return this.collection.value;
    }

    set state(data) {
        this.collection.value = data;
    }

    get isLoaded() {
        return this.loaded.value;
    }

    get isLoading() {
        return this.loading.value;
    }

    item(index) {
        return Number.isInteger(index) && index < this.collection.value.length
            ? this.collection.value[index]
            : null;
    }

    find(params = {}) {
        this.loading.value = true;

        if (this.includes) {
            params.include = this.includes.join(',');
        }

        if (this.sortBy) {
            params[this.sortDir || 'asc'] = this.sortBy;
        }

        return STACK.push(() => {
            return ApiClient.get(`${this.endpoint}/search`, {params, headers: {'Ref': this.referer}}).then(response => {
                this.collection.value = response.data.data || response.data || [];
                this.loaded.value = true;
                this.loading.value = false;
                this.meta.total = 1;
                //Object.keys(this.meta).forEach(key => delete this.meta[key]);

                return response;
            });
        });
    }

    buildParams(filters = null, options = {}) {
        const params = {};

        filters = filters || this.filters;

        for (const key in filters) {
            if (Object.keys(filters[key]).length) {
                params[key] = Object.values(filters[key]).join();
            }
        }

        const includes = options.includes || this.includes;
        if (includes) {
            params['include'] = includes.join(',');
        }

        const sortBy = options.sortBy || this.sortBy;
        if (sortBy) {
            params[this.sortDir || 'asc'] = this.sortBy;
        }

        const perPage = options.perPage || this.perPage;
        if (perPage) {
            params['per_page'] = perPage;
            params['page'] = options.page || this.page || 1;
        }

        return params;
    }

    load(filters = null, options = {}, referer = null) {

        this.loaded.value = false;
        this.loading.value = true;

        const params = this.buildParams(filters, options);

        return STACK.push(() => ApiClient.get(this.endpoint, {
            params,
            headers: {'Ref': referer || this.referer}
        })).then(response => {
            this.collection.value = response.data.data || response.data || [];
            this.loaded.value = true;
            this.loading.value = false;

            if (response.meta) {
                Object.assign(this.meta, response.meta);
            }

            if (response.filters) {
                 Object.assign(this.filterset, response.filters);
            }

            if (Array.isArray(this.onload)) {
                this.onload.forEach(action => action(response));
            } else if (typeof this.onload === 'function') {
                this.onload();
            }

            //ResourcesManager.addResource(this);

            return response;
        });
    }

    show(id = null, params = {}) {
        return STACK.push(() => {
            return ApiClient.get(`${this.endpoint}/${id || this.entity.id}`, { params, headers: {'Ref': this.referer}})
            .then(response => {
                const index = this.collection.value.findIndex(item => item.id === id);
                if (index > -1) {
                    Object.assign(this.collection.value[index], response.data.data);
                } else {
                    response.data.data.mixed = true;
                    this.collection.value.push(response.data.data)
                }

                return response.data;
            });
        });
    }

    save(id = null, payload = null) {
        return STACK.push(() => {
            return ApiClient.patch(
                `${this.endpoint}/${id || this.model.id}`,
                payload || this.entity,
                {headers: {'Ref': this.referer}}
            );
        });
    }

    update(payload = null) {
        return STACK.push(() => {
            return ApiClient.patch(this.endpoint, payload, {headers: {'Ref': this.referer}});
        });
    }

    create(payload) {
        return STACK.push(() => {
            return ApiClient.post(this.endpoint, payload, {headers: {'Ref': this.referer}});
        }).then(response => {
            this.collection.value.unshift(response.data);
            this.idx.value = this.collection.value.length - 1;

            return response.data;
        });
    }

    delete(id = null, payload = null) {
        const options = {
            headers: {'Ref': this.referer},
        };

        if (payload) {
            options['data'] = payload;
        }

        return STACK.push(() => {
            return ApiClient.delete(`${this.endpoint}/${id || this.entry.id}`, options);
        });
    }

    clearEntity() {
        Object.keys(this.entity).forEach(key => delete this.entity[key]);
    }

    setFilters(filters) {
        this.clearFilters();
        Object.assign(this.filters, filters);
    }

    addFilter(fName, fValue) {
        if (!!this.filters[fName]) {
            this.filters[fName].push(fValue);
        } else {
            this.filters[fName] = [fValue];
        }

    }

    dropFilter(fName) {
        delete this.filters[fName];
    }

    dropFilterValue(fName, value) {
        delete this.filters[fName][value];
        if (Object.keys(this.filters[fName]).length === 0) {
            delete this.filters[fName];
        }
    }

    clearFilters() {
        Object.keys(this.filters).forEach(key => this.dropFilter(key));
    }

    setIncludes(includes) {
        this.includes = includes;
    }

    addInclude(include) {
        this.includes.push(include);
    }

    dropInclude(include) {
        this.includes.splice(this.includes.indexOf(include), 1);
    }

    clearIncludes() {
        this.includes = null;
    }

    sort(sortBy, sortDir = null) {
        this.sortBy = sortBy;
        this.sortDir = sortDir || this.sortDir;
    }

    resort(field) {
        if (field === this.sortBy) {
            this.sortDir = this.sortDir === 'asc'
                ? 'desc'
                : 'asc';
        } else {
            this.sortBy = field;
            this.sortDir = 'asc';
        }

        this.load();
    }

    setPerPage(perPage) {
        this.perPage = perPage;
    }

    dropPerPage() {
        this.perPage = null;
    }

    setPage(page) {
        this.page = page;
    }
}
