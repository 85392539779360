
class ResourcesManager {
    static #instance = null;

    resources = {};

    constructor() {
        if (!ResourcesManager.#instance) {
            ResourcesManager.#instance = this;
        }

        const requireResources = require.context('@/Containers', true, /[\w-]+\/[\w-]+\/resources\/[\w-]+\.js$/);
        requireResources.keys().forEach(fileName => {
            const resourceName = fileName.split('/').pop().replace(/\.\w+$/, '');
            ResourcesManager.#instance.resources[resourceName] = requireResources(fileName).default;
        });

        return ResourcesManager.#instance;
    }

    static getInstance() {
        return new ResourcesManager();
    }

    getResource(resourceName, reload = false) {
        if (typeof this.resources[resourceName] === 'function') {
            this.resources[resourceName] = this.resources[resourceName].getInstance();
        }

        if (reload) {
            this.reload(resourceName);
        }

        return this.resources[resourceName];
    }

    addResource(resource, key = null) {
        key = key || resource.constructor.name;

        if (typeof this.resources[key] === 'function') {
            this.resources[key] = resource;
        }
    }

    reload(resourceName) {
        if (typeof this.resources[resourceName] === 'function') {
            this.resources[resourceName] = this.resources[resourceName].getInstance();
        }

        const resource = this.resources[resourceName];

        resource.load();
    }

    reloadAll(exclude = []) {
        console.log('###--->')
        Object.keys(this.resources).forEach(key => {
            //console.log('[ResourcesManager]: ', key, typeof this.resources[key], this.resources[key].isLoaded)
            if ((typeof this.resources[key] === 'object') && !exclude.includes(key) && this.resources[key].isLoaded) {
                this.reload(key)
            }
        });
    }
}

export default ResourcesManager.getInstance();